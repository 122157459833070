<template>
  <div class="delivery">
    <div class="delivery__steps-wrapper">
      <div class="delivery__steps">
        <Steps :current="steps" />
      </div>
    </div>

    <div class="delivery__form">
      <a href="">
        <div class="delivery__back-to-cart">
          <span class="material-icons">arrow_back</span> Back to cart
        </div>
      </a>

      <div class="delivery__form-content">
        <form class="delivery__form-input" @submit.prevent>
          <div class="delivery__form-section">
            <div class="delivery__heading">
              <div class="delivery__head-title">
                <Title text="Delivery details" />
              </div>

              <div class="delivery__dropship-section">
                <div>
                  <label class="delivery__checkbox">
                    <input type="checkbox" v-model="deliveryState.dropship" :class="{'delivery__dropshipper-checkbox': true}" @keydown.enter.prevent />
                    <span class="delivery__checkbox-icon">
                      <span class="material-icons">check</span>
                    </span>
                    Send as dropshipper
                  </label>
                </div>

              </div>
            </div>

            <div class="delivery__inputs">
              <div class="delivery__input-containing">
                <div :class="{'delivery__input-icon-wrapper': true, 'delivery__input-icon-wrapper--error': errors.has('name') && deliveryState.name.length != 0, 'delivery__input-icon-wrapper--success': !errors.has('name') && deliveryState.name.length != 0}">
                  <div class="delivery__input-and-icon" >
                    <div class="delivery__input-wrap">
                      <input type="text"
                             v-model="deliveryState.name"
                             v-validate.initial="'required'"
                             class="delivery__input-text"
                             placeholder=" "
                             name="name"
                             @keydown.enter.prevent
                      >
                      <label
                              for="name"
                              class="delivery__input-label"
                              :class="{'delivery__input-label--error': errors.has('name') && deliveryState.name.length != 0, 'delivery__input-label--success': !errors.has('name') && deliveryState.name.length != 0}">
                        Name
                      </label>
                    </div>

                    <div class="delivery__input-status" v-if="deliveryState.name.length != 0">
                      <div v-if="!errors.has('name')"><span class="material-icons" :style="{ color: '#1bd97b' , fontSize: '18px'}">check</span></div>
                      <div v-if="errors.has('name')"><span class="material-icons" :style="{ color: '#ff8a00' , fontSize: '18px'}">clear</span></div>
                    </div>
                  </div>
                </div>

                <div class="delivery__input-error-msg" v-if="errors.has('name') && deliveryState.name.length != 0">
                  {{ errors.first('name') }}
                </div>
              </div>

              <div class="delivery__input-containing" :class="{'delivery__input-containing--disabled': !deliveryState.dropship}">
                <div :class="{'delivery__input-icon-wrapper': true, 'delivery__input-icon-wrapper--error': errors.has('dropshipper_name') && deliveryState.dropshipperName.length != 0 && deliveryState.dropship, 'delivery__input-icon-wrapper--success': !errors.has('dropshipper_name') && deliveryState.dropshipperName.length != 0 && deliveryState.dropship, 'delivery__input-icon-wrapper--disabled': !deliveryState.dropship}">
                  <div class="delivery__input-and-icon" >
                    <div class="delivery__input-wrap">
                      <input type="text"
                             v-model="deliveryState.dropshipperName"
                             v-validate.initial="'required'"
                             class="delivery__input-text"
                             placeholder=" "
                             name="dropshipper_name"
                             :disabled="deliveryState.dropship == false"
                             @keydown.enter.prevent
                      >
                      <label
                              for="dropshipper_name"
                              class="delivery__input-label"
                              :class="{'delivery__input-label--error': errors.has('dropshipper_name') && deliveryState.dropshipperName.length != 0 && deliveryState.dropship, 'delivery__input-label--success': !errors.has('dropshipper_name') && deliveryState.dropshipperName.length != 0 && deliveryState.dropship}">
                        Dropshipper Name
                      </label>
                    </div>

                    <div class="delivery__input-status" v-if="deliveryState.dropshipperName.length != 0">
                      <div v-if="!errors.has('dropshipper_name') && deliveryState.dropship"><span class="material-icons" :style="{ color: '#1bd97b' , fontSize: '18px'}">check</span></div>
                      <div v-if="errors.has('dropshipper_name') && deliveryState.dropship"><span class="material-icons" :style="{ color: '#ff8a00' , fontSize: '18px'}">clear</span></div>
                    </div>
                  </div>
                </div>

                <div class="delivery__input-error-msg" v-if="errors.has('dropshipper_name') && deliveryState.dropshipperName.length != 0 && deliveryState.dropship">
                  {{ errors.first('dropshipper_name') }}
                </div>
              </div>

              <div class="delivery__input-containing">
                <div :class="{'delivery__input-icon-wrapper': true, 'delivery__input-icon-wrapper--error': errors.has('phone_number') && deliveryState.phoneNumber.length != 0, 'delivery__input-icon-wrapper--success': !errors.has('phone_number') && deliveryState.phoneNumber.length != 0}">
                  <div class="delivery__input-and-icon">
                    <div class="delivery__input-wrap">
                      <input type="text"
                             v-model="deliveryState.phoneNumber"
                             v-validate.initial="{ required: true, min: 6, max: 20, regex: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/ }"
                             class="delivery__input-text"
                             placeholder=" "
                             name="phone_number"
                             @keydown.enter.prevent
                      >

                      <label
                              for="phone_number"
                              class="delivery__input-label"
                              :class="{'delivery__input-label--error': errors.has('phone_number') && deliveryState.phoneNumber.length != 0, 'delivery__input-label--success': !errors.has('phone_number') && deliveryState.phoneNumber.length != 0}">
                        Phone Number
                      </label>
                    </div>

                    <div class="delivery__input-status" v-if="deliveryState.phoneNumber.length != 0">
                      <div v-if="!errors.has('phone_number')"><span class="material-icons" :style="{ color: '#1bd97b' , fontSize: '18px'}">check</span></div>
                      <div v-if="errors.has('phone_number')"><span class="material-icons" :style="{ color: '#ff8a00' , fontSize: '18px'}">clear</span></div>
                    </div>
                  </div>
                </div>

                <div class="delivery__input-error-msg" v-if="errors.has('phone_number') && deliveryState.phoneNumber.length != 0">
                  {{ errors.first('phone_number') }}
                </div>
              </div>

              <div class="delivery__input-containing" :class="{'delivery__input-containing--disabled': !deliveryState.dropship}">
                <div :class="{'delivery__input-icon-wrapper': true, 'delivery__input-icon-wrapper--error': errors.has('dropshipper_phone_number') && deliveryState.dropshipperPhoneNumber.length != 0 && deliveryState.dropship, 'delivery__input-icon-wrapper--success': !errors.has('dropshipper_phone_number') && deliveryState.dropshipperPhoneNumber.length != 0 && deliveryState.dropship, 'delivery__input-icon-wrapper--disabled': !deliveryState.dropship}">
                  <div class="delivery__input-and-icon">
                    <div class="delivery__input-wrap">
                      <input type="text"
                             v-model="deliveryState.dropshipperPhoneNumber"
                             v-validate.initial="{ required: true, min: 6, max: 20, regex: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/ }"
                             class="delivery__input-text"
                             placeholder=" "
                             name="dropshipper_phone_number"
                             :disabled="deliveryState.dropship == false"
                             @keydown.enter.prevent
                      >

                      <label
                              for="dropshipper_phone_number"
                              class="delivery__input-label"
                              :class="{'delivery__input-label--error': errors.has('dropshipper_phone_number') && deliveryState.dropshipperPhoneNumber.length != 0 && deliveryState.dropship, 'delivery__input-label--success': !errors.has('dropshipper_phone_number') && deliveryState.dropshipperPhoneNumber.length != 0 && deliveryState.dropship}">
                        Dropshipper Phone Number
                      </label>
                    </div>

                    <div class="delivery__input-status" v-if="deliveryState.dropshipperPhoneNumber.length != 0">
                      <div v-if="!errors.has('dropshipper_phone_number') && deliveryState.dropship"><span class="material-icons" :style="{ color: '#1bd97b' , fontSize: '18px'}">check</span></div>
                      <div v-if="errors.has('dropshipper_phone_number') && deliveryState.dropship"><span class="material-icons" :style="{ color: '#ff8a00' , fontSize: '18px'}">clear</span></div>
                    </div>
                  </div>
                </div>

                <div class="delivery__input-error-msg" v-if="errors.has('dropshipper_phone_number') && deliveryState.dropshipperPhoneNumber.length != 0 && deliveryState.dropship">
                  {{ errors.first('dropshipper_phone_number') }}
                </div>
              </div>

              <div class="delivery__input-containing">
                <div :class="{'delivery__input-icon-wrapper': true, 'delivery__input-icon-wrapper--error': errors.has('delivery_address') && deliveryState.deliveryAddress.length != 0, 'delivery__input-icon-wrapper--success': !errors.has('delivery_address') && deliveryState.deliveryAddress.length != 0}">
                  <div class="delivery__input-and-icon" >
                    <div class="delivery__input-wrap">
                        <textarea
                                rows="5"
                                v-model="deliveryState.deliveryAddress"
                                v-validate.initial="'required|min:6|max:120'"
                                class="delivery__input-text delivery__input-text--no-resize"
                                placeholder=" "
                                name="delivery_address"
                                :maxlength="120">
                        </textarea>

                      <label
                              for="delivery_address"
                              class="delivery__input-label"
                              :class="{'delivery__input-label--error': errors.has('delivery_address') && deliveryState.deliveryAddress.length != 0, 'delivery__input-label--success': !errors.has('delivery_address') && deliveryState.deliveryAddress.length != 0}">
                        Delivery Address (120/{{ deliveryState.deliveryAddress.length }})
                      </label>
                    </div>

                    <div class="delivery__input-status" v-if="deliveryState.deliveryAddress.length != 0">
                      <div v-if="!errors.has('delivery_address')"><span class="material-icons" :style="{ color: '#1bd97b' , fontSize: '18px'}">check</span></div>
                      <div v-if="errors.has('delivery_address')"><span class="material-icons" :style="{ color: '#ff8a00' , fontSize: '18px'}">clear</span></div>
                    </div>
                  </div>
                </div>

                <div class="delivery__input-error-msg" v-if="errors.has('delivery_address') && deliveryState.deliveryAddress.length != 0">
                  Type at least 6 characters, maximum 120 characters
                </div>
              </div>
            </div>
          </div>

          <div class="delivery__summary-section">
            <div>
              <Summary btnText="Continue to Payment" @submit="submitDelivery" :steps="steps" />
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import Steps from '@/components/steps/Steps.vue';
import Summary from '@/components/summary/Summary.vue';
import Title from '@/components/title/PageTitle.vue';

export default {
  name: 'Delivery',
  components: {
    Steps, Title, Summary
  },
  data() {
    return {
      steps: {
        delivery: true,
        payment: false,
        success: false
      },
      isTicked: true,
    }
  },
  watch: {
    'deliveryState.dropship': function (newValue) {
      if (!newValue) {
        this.deliveryState.dropshipperName = '';
        this.deliveryState.dropshipperPhoneNumber = '';
      }
    }
  },
  computed: {
      ...mapState(['deliveryState'])
  },
  methods: {
      ...mapMutations(['updateDeliveryState']),
      submitDelivery() {
          this.$validator.validateAll().then(result => {
              if (result) {
                  this.updateDeliveryState(this.deliveryState);
                  this.$router.push({ name: 'Payment' });
              } else {
                  alert("Please fill out the form.");
              }
          });
      }
  }
}
</script>

<style lang='stylus' scoped>
@import './delivery.styl';
</style>
<template>
    <div class="payment">
        <div class="payment__steps-wrapper">
            <div class="payment__steps">
                <Steps :current="steps" />
            </div>
        </div>

        <div class="payment__form">
            <router-link :to="{ name: 'Delivery' }">
                <div class="payment__back-to-cart">
                    <span class="material-icons">arrow_back</span> Back to delivery
                </div>
            </router-link>

            <div class="payment__form-content">
                <div class="payment__form-section">
                    <div class="payment__shipment">
                        <div class="payment__shipment-title">
                            <Title text="Shipment" />
                        </div>

                        <div class="payment__shipment-list">
                            <div v-for="(data, index) in shipments" :key="index" class="payment__item" :class="{ 'payment__item--checked': data.id === selectedShipment.id }" @click="updateShipment(data.id)">
                                <div class="payment__wrap-item">
                                    <div class="payment__item-info">
                                        <div class="payment__item-label">{{ data.name }}</div>
                                        <div class="payment__item-price payment__item-price--bold">{{ data.desc }}</div>
                                    </div>
                                </div>

                                <div class="payment__item-checked">
                                    <div v-if="data.id === selectedShipment.id">
                                        <span class="material-icons">check</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="payment__method">
                        <div class="payment__method-title">
                            <Title text="Payment" />
                        </div>

                        <div class="payment__method-list">
                            <div v-for="(data, index) in payments" :key="index" class="payment__item" :class="{ 'payment__item--checked': data.id === selectedPayment.id }" @click="updatePayment(data.id)">
                                <div class="payment__wrap-item">
                                    <div class="payment__item-info">
                                        <div class="payment__item-label">{{ data.name }}</div>
                                        <div v-if="data.desc !== ''" class="payment__item-price payment__item-price--bold" :class="{ 'payment__item-price--hide': data.desc === '' }">{{ data.desc }}</div>
                                    </div>
                                </div>

                                <div class="payment__item-checked">
                                    <div v-if="data.id === selectedPayment.id">
                                        <span class="material-icons">check</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="payment__summary-section">
                    <div>
                        <Summary :btnText="'Pay with ' + selectedPayment.name" @submit="submitPayment" :dropship="true" :steps="steps" :shipment="selectedShipment" :payment="selectedPayment" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations } from 'vuex';
import Steps from '@/components/steps/Steps.vue';
import Summary from '@/components/summary/Summary.vue';
import Title from '@/components/title/PageTitle.vue';

export default {
    name: 'Payment',
    components: {
        Steps, Summary, Title
    },
    data() {
        return {
            selectedShipment: {
                ...this.$store.state.shipmentState
            },
            selectedPayment: {
                ...this.$store.state.paymentState
            },
            shipments: [
                { id: 0, name: 'GO-SEND', desc: '15,000', estimation: 'today', price: 15000 },
                { id: 1, name: 'JNE', desc: '9,000', estimation: '2 days', price: 9000 },
                { id: 2, name: 'Personal Courier', desc: '29,000', estimation: '1 day', price: 29000 }
            ],
            payments: [
                { id: 0, name: 'e-Wallet', desc: '1,500,000 left' },
                { id: 1, name: 'Bank Transfer', desc: '' },
                { id: 2, name: 'Virtual Account', desc: '' }
            ],
            steps: {
                delivery: true,
                payment: true,
                success: false
            }
        }
    },
    methods: {
        ...mapMutations(['updateShipmentState', 'updatePaymentState']),
        updateShipment(id) {
            const shipmentId = this.shipments.findIndex(shipment => shipment.id === id);
            const shipment = { ...this.shipments[shipmentId] };

            this.updateShipmentState(shipment);
            this.selectedShipment = shipment;
        },
        updatePayment(id) {
            const paymentId = this.payments.findIndex(payment => payment.id === id);
            const payment = { ...this.payments[paymentId] };

            this.updatePaymentState(payment);
            this.selectedPayment = payment;
        },
        submitPayment() {
            if (!this.selectedShipment.name || !this.selectedPayment.name) {
                alert("Please select a shipment and payment option.");
            } else {
                this.$router.push({ name: 'Success' });
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='stylus' scoped>
@import './payment.styl';
</style>

<template>
  <div class="steps">
    <ul class="steps__list">
      <li class="steps__item steps__item--first" :class="{ 'steps__item--active': current.delivery && !current.payment }">
        <span class="steps__badge" :class="{ 'steps__badge--finished': current.delivery }">1</span> Delivery
      </li>
      <li class="steps__item"><span class="steps__arrow"><span class="material-icons">keyboard_arrow_right</span></span></li>
      <li class="steps__item" :class="{ 'steps__item--active': current.payment && !current.success }">
        <span class="steps__badge" :class="{ 'steps__badge--finished': current.payment }">2</span> Payment
      </li>
      <li class="steps__item"><span class="steps__arrow"><span class="material-icons">keyboard_arrow_right</span></span></li>
      <li class="steps__item steps__item--last" :class="{ 'steps__item--active': current.success }">
        <span class="steps__badge" :class="{ 'steps__badge--finished': current.success }">3</span> Finish
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Steps',
  props: ['current']
}
</script>

<style lang='stylus' scoped>
@import './steps.styl';
</style>
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const state = {
  deliveryState: JSON.parse(localStorage.getItem('deliveryState')) || {
    name: '',
    dropshipperName: '',
    phoneNumber: '',
    dropshipperPhoneNumber: '',
    deliveryAddress: '',
    dropship: false
  },
  shipmentState: JSON.parse(localStorage.getItem('shipmentState')) || {
    id: 0,
    name: 'GO-SEND',
    desc: '15,000',
    estimation: 'today',
    price: 15000
  },
  paymentState: JSON.parse(localStorage.getItem('paymentState')) || {
    id: 0,
    name: 'e-Wallet',
    desc: '1,500,000 left'
  }
};

const mutations = {
  updateDeliveryState(state, delivery) {
    state.deliveryState = { ...delivery };
    localStorage.setItem('deliveryState', JSON.stringify(delivery));
  },
  updateShipmentState(state, shipment) {
    state.shipmentState = { ...shipment };
    localStorage.setItem('shipmentState', JSON.stringify(shipment));
  },
  updatePaymentState(state, payment) {
    state.paymentState = { ...payment };
    localStorage.setItem('paymentState', JSON.stringify(payment));
  }
};

export default new Vuex.Store({
  state,
  mutations
});

<template>
    <div class="success">
        <div class="success__steps-wrapper">
            <div class="success__steps">
                <Steps :current="steps" />
            </div>
        </div>

        <div class="success__form">
            <div class="success__form-content">
                <div class="success__form-section">
                    <div class="success__form-wrapper">
                        <div>
                            <Title text="Thank you" />
                        </div>
                        <div class="success__order">
                            <div>Order ID : {{ orderID }}</div>
                            <div class="success__order-message">Your order will be delivered today with {{ shipmentState.name }}</div>
                        </div>
                        <div class="success__back-to-homepage" @click="backAndResetState">
                            <span class="material-icons">arrow_back</span> Go to homepage
                        </div>
                    </div>
                </div>

                <div class="success__summary-section">
                    <div>
                        <Summary :steps="steps" :shipmentPrice="shipmentPrice" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import Steps from '@/components/steps/Steps.vue';
import Summary from '@/components/summary/Summary.vue';
import Title from '@/components/title/PageTitle.vue';

export default {
    name: 'Success',
    components: {
        Steps, Summary, Title
    },
    computed: {
        ...mapState({
            shipmentState: (state) => state.shipmentState
        })
    },
    data() {
        return {
            shipmentPrice: this.$route.params.shipmentPrice,
            steps: {
                delivery: true,
                payment: true,
                success: true
            },
            orderID: null
        }
    },
    created() {
        this.orderID = localStorage.getItem('orderID');
        if (!this.orderID) {
            this.orderID = this.randomCode(5);
            localStorage.setItem('orderID', this.orderID);
        }
    },
    methods: {
        randomCode(len) {
            const chars = '23456789ABCDEFGHJKLMNPQRSTUVWXYZ';
            let code = '';

            for (let i = 0; i < len; i++) {
                const randomIndex = Math.floor(Math.random() * chars.length);
                code += chars[randomIndex];
            }

            return code;
        },
        backAndResetState() {
            localStorage.removeItem('deliveryState');
            localStorage.removeItem('shipmentState');
            localStorage.removeItem('paymentState');
            localStorage.removeItem('orderID');

            this.$store.commit('updateDeliveryState', {
                name: '',
                dropshipperName: '',
                phoneNumber: '',
                dropshipperPhoneNumber: '',
                deliveryAddress: '',
                dropship: false
            });
            this.$store.commit('updateShipmentState', {
                id: 0,
                name: 'GO-SEND',
                desc: '15,000',
                estimation: 'today',
                price: 15000
            });
            this.$store.commit('updatePaymentState', {
                id: 0,
                name: 'e-Wallet',
                desc: '1,500,000 left'
            });

            this.$router.push({ path: '/' });
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='stylus' scoped>
@import './success.styl';
</style>

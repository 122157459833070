import Vue from 'vue'
import Router from 'vue-router'
import Delivery from '@/pages/delivery/Delivery.vue'
import Payment from "@/pages/payment/Payment.vue";
import Success from "@/pages/success/Success.vue";

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/delivery'
    },
    {
      path: '/delivery',
      name: 'Delivery',
      component: Delivery
    },
    {
      path: '/payment',
      name: 'Payment',
      component: Payment
    },
    {
      path: '/success',
      name: 'Success',
      component: Success
    },
  ]
})

<template>
	<div class="summary">
		<div class="summary__title">
			Summary
		</div>

		<div class="summary__purchased-item summary__purchased-item--big">
			10 items purchased
		</div>

		<div :class="['summary__separator', { 'summary__separator--hidden': !steps.payment }]"></div>

		<div class="summary__delivery-estimation summary__delivery-estimation--big" :class="{'summary__delivery-estimation--hidden': !steps.payment}">
			<div>Delivery estimation</div>
			<div class="summary__chosen-delivery">{{ shipment.estimation || shipmentState.estimation }} by {{ shipment.name || shipmentState.name }}</div>
		</div>

		<div :class="['summary__separator', { 'summary__separator--hidden': !steps.success }]"></div>

		<div class="summary__payment-method summary__payment-method--big" :class="{'summary__payment-method--hidden': !steps.success}">
			<div>Payment method</div>
			<div class="summary__chosen-method">{{ payment.name || paymentState.name }}</div>
		</div>

		<div class="summary__calculation">
			<div class="summary__calc-list">
				<div class="summary__calc-item">
					<div class="summary__calc-label">Cost of goods</div>
					<div class="summary__calc-price">500,000</div>
				</div>

				<div class="summary__calc-item" :class="{'summary__calc-item--hidden': !deliveryState.dropship}">
					<div class="summary__calc-label">Dropshipping Fee</div>
					<div class="summary__calc-price">5,900</div>
				</div>

				<div class="summary__calc-item" :class="{'summary__calc-item--hidden': !steps.payment}">
					<div class="summary__calc-label">
						<span class="summary__calc-label-highlight">{{ shipment.name || shipmentState.name }}</span> shipment
					</div>
					<div class="summary__calc-price">{{ shipment.desc || shipmentState.desc }}</div>
				</div>
			</div>

			<div class="summary__total">
				<div class="summary__total-label">Total</div>
				<div class="summary__total-number">
					<span v-if="!deliveryState.dropship">{{ (500000 + (shipmentState.price || shipmentPrice)).toLocaleString() }}</span>
					<span v-else>{{ (500000 + 5900 + (shipmentState.price || shipmentPrice)).toLocaleString() }}</span>
				</div>
			</div>
		</div>

		<div class="summary__pay" v-if="steps.success !== true">
			<button type="submit" @click="submit" class="summary__pay-button">{{ btnText }}</button>
		</div>
	</div>
</template>

<script>

export default {
	name: 'Summary',
	props: {
		steps: {
			default: function() {
				return {
					delivery: false,
					payment: false,
					success: false
				}
			},
			type: Object
		},
		btnText: {
			default: 'Process',
			type: String
		},
		shipment: {
			default: function() {
				return {
					id: undefined,
					name: '',
					desc: '',
					estimation: '',
					price: 0
				}
			},
			type: Object
		},
		shipmentPrice: {
			default: 0
		},
		payment: {
			default: function() {
				return {
					id: undefined,
					name: '',
					desc: ''
				}
			},
			type: Object
		}
	},
	computed: {
		shipmentState() {
			if (this.steps.payment || this.steps.success) {
				return this.$store.state.shipmentState;
			} else {
				return {
					id: undefined,
					name: '',
					desc: '',
					estimation: '',
					price: 0
				};
			}
		},
		paymentState() {
			return this.$store.state.paymentState;
		},
		deliveryState() {
			return this.$store.state.deliveryState;
		}
	},
	methods: {
		submit() {
			this.$emit('submit');
		}
	}
};
</script>

<style lang="stylus" scoped>
@import './summary.styl';
</style>
